import classNames from 'classnames'
import Link from 'next/link'
import React from 'react'
import { createLink, Routes } from 'src/client/routes'

import styles from './navbar-logo.module.scss'
import { NavbarLogoProps } from './navbar-logo.types'

export const NavbarLogo = ({ noWrapper }: NavbarLogoProps) => (
  <span className={classNames({ [styles.logoWrapper]: !noWrapper })}>
    <Link href={createLink(Routes.Pages_Home)}>
      <img src="/svgs/logo.svg" width="140" alt="Piesotto" className="cursor-pointer" />
    </Link>
  </span>
)
