import { useStateMachine } from 'little-state-machine'
import isEmpty from 'lodash/isEmpty'
import { CreateDogInterface } from 'piesotto-shared/dog-dtos/post-dog-payload-interface.dto'
import { Proposal } from 'piesotto-shared/proposal-types/proposal-type'
import { PlanStorageState } from 'src/client/common/@types/plan-storage-state.types'
import { getProposal } from 'src/client/common/api-calls/get-proposal'
import { mapStorageDogsToPayload } from 'src/client/modules/plan/common/map-storage-dogs-to-payload'
import { getPlanData } from 'src/client/modules/plan/plan-storage/plan-data/get-plan-data'

import { removeProposalAction } from './remove-proposal-action'
import { setProposalFetchedAction } from './set-proposal-fetched-action'
import { setProposalFetchingAction } from './set-proposal-fetching-action'
import { updateProposalAction } from './update-proposal-action'

type FetchProposalParams = {
  dogs?: CreateDogInterface[]
  discountCode?: string
}

export const useProposal = () => {
  const {
    state: { proposal, isProposalFetching: isFetching, isProposalFetched: isFetched },
    actions
  } = useStateMachine({
    updateProposalAction,
    removeProposalAction,
    setProposalFetchingAction,
    setProposalFetchedAction
  })

  const removeProposal = () => {
    actions.removeProposalAction(undefined)
  }

  const initProposal = async () => {
    removeProposal()
    actions.setProposalFetchedAction({ value: false })
    actions.setProposalFetchingAction({ value: false })
  }

  const updateProposal = (proposal: Proposal) => {
    actions.updateProposalAction({ proposal })
  }

  const fetchProposal = async (params: FetchProposalParams) => {
    const dogs = params?.dogs
    const discountCode = params?.discountCode

    let dogsData = []

    if (!dogs) {
      const planData = await getPlanData()

      if (isEmpty(planData)) {
        return Promise.reject()
      }

      const userDogs = (planData as PlanStorageState).dogs

      dogsData = mapStorageDogsToPayload(userDogs)
    } else {
      dogsData = dogs
    }

    actions.setProposalFetchingAction({ value: true })

    const proposal: Proposal = await getProposal({ dogs: dogsData, discountCode })

    updateProposal(proposal)

    // The order matters! "Fetched" should be set to true first, then "Fetching" to false.
    actions.setProposalFetchedAction({ value: true })
    actions.setProposalFetchingAction({ value: false })

    return Promise.resolve(proposal)
  }

  return {
    fetchProposal,
    initProposal,
    isFetched,
    isFetching,
    proposal,
    removeProposal,
    updateProposal
  }
}
