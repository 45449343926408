export enum CookieStorage {
  ACTIVE_DOG_KEY = 'active-dog',
  ALLOW_COOKIE = 'allowCookie',
  COOKIE_CONSENT = 'CookieConsent',
  DISCOUNT = 'discount',
  DISCOUNT_CODE = 'discountCode',
  EXTEND_SUBSCRIPTION_DATA = 'extend-subscription',
  OFFER = 'offer',
  ON_LOGIN_POPUP_SHOWN = 'onLoginPopupShown',
  PLAN_STORAGE_KEY = 'plan-storage'
}
