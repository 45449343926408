import { GlobalState } from 'little-state-machine'
import { Proposal } from 'piesotto-shared/proposal-types/proposal-type'

export const updateProposalAction = (globalState: GlobalState, payload: { proposal: Proposal }) => {
  const { proposal } = payload

  if (!proposal) {
    return {
      ...globalState
    }
  }

  return {
    ...globalState,
    proposal
  }
}
