import { DogFoodEnum } from 'piesotto-shared/dog-dtos/dog-food.enum'
import {
  PROPOSAL_HIDDEN_FLAVORS,
  INITIAL_PROPOSAL_FLAVORS
} from 'piesotto-shared/dog-dtos/food-flavor.enum'
import { PackageModifierEnum } from 'piesotto-shared/dog-dtos/package-modifier.enum'
import { CreateDogInterface } from 'piesotto-shared/dog-dtos/post-dog-payload-interface.dto'
import { DogStorageState } from 'src/client/common/@types/plan-storage-state.types'

import { dogBreedConfig } from '../forms/breed-form/dogBreedConfig'
import { TreatsValuesEnum } from '../forms/food-preferences-form/treats-buttons'
import { SterilizationValuesEnum } from '../forms/sterilization-form/sterilization-buttons'

const { MONGREL_ID } = dogBreedConfig

export const mapStorageDogsToPayload = (dogs: DogStorageState[]): CreateDogInterface[] =>
  dogs.map(dog => {
    const { plan } = dog

    const breed = plan.breedChoice ? parseInt(plan.breedChoice, 10) : MONGREL_ID

    const initialProposalFlavorsContainHiddenFlavors = INITIAL_PROPOSAL_FLAVORS.some(flavor =>
      PROPOSAL_HIDDEN_FLAVORS.includes(flavor)
    )

    const flavors = initialProposalFlavorsContainHiddenFlavors
      ? INITIAL_PROPOSAL_FLAVORS.filter(flavor => !PROPOSAL_HIDDEN_FLAVORS.includes(flavor))
      : INITIAL_PROPOSAL_FLAVORS

    return {
      activity: plan.activity,
      age: {
        years: parseInt(plan.yearAge, 10),
        months: parseInt(plan.monthAge, 10)
      },
      allergies: plan?.allergies || [],
      appetite: plan.appetite,
      breed,
      diseases: plan?.diseases || [],
      figure: plan.figure,
      flavors,
      food: [plan.foodPreferences],
      foodBrand: plan.foodBrand && plan.foodPreferences !== DogFoodEnum.Raw ? plan.foodBrand : '',
      getsTreat: plan.hasTreats === TreatsValuesEnum.GetsTreats,
      name: dog.name,
      packageModifier: plan.packageModifier || PackageModifierEnum.Default,
      sex: plan.sex,
      sterilization: plan.sterilization === SterilizationValuesEnum.STERILIZED,
      type: plan.breedType,
      weight: Number(plan.weight)
    }
  })
