import { CustomerPlanTypeEnum } from 'piesotto-shared/customer-dtos/customer-plan-enum'
import { CreateDogInterface } from 'piesotto-shared/dog-dtos/post-dog-payload-interface.dto'
import { ProposalPayloadInterfaceDto } from 'piesotto-shared/proposal-dtos/proposal-payload-interface.dto'
import { Proposal } from 'piesotto-shared/proposal-types/proposal-type'

import { fetchApi } from '../api-caller/api-caller'

type GetProposalParams = {
  dogs: CreateDogInterface[]
  discountCode?: string | null
}

export const getProposal = async ({ dogs, discountCode }: GetProposalParams): Promise<Proposal> => {
  const proposalPayload: ProposalPayloadInterfaceDto = {
    dogs,
    planType: null,
    discountCode: discountCode || null
  }

  const proposal: Proposal = {
    dogs: proposalPayload.dogs,
    [CustomerPlanTypeEnum.Mix]: null,
    [CustomerPlanTypeEnum.OneTime]: null,
    [CustomerPlanTypeEnum.Subscription]: null
  }

  try {
    const proposalPromiseMix = fetchApi('/proposal', {
      method: 'POST',
      body: JSON.stringify({
        ...proposalPayload,
        planType: CustomerPlanTypeEnum.Mix
      })
    })

    const proposalPromiseOneTime = fetchApi('/proposal', {
      method: 'POST',
      body: JSON.stringify({
        ...proposalPayload,
        planType: CustomerPlanTypeEnum.OneTime
      })
    })

    const proposalPromiseSubscription = fetchApi('/proposal', {
      method: 'POST',
      body: JSON.stringify({
        ...proposalPayload,
        planType: CustomerPlanTypeEnum.Subscription
      })
    })

    const [proposalResponseMix, proposalResponseOneTime, proposalResponseSubscription] =
      await Promise.all([proposalPromiseMix, proposalPromiseOneTime, proposalPromiseSubscription])

    proposal[CustomerPlanTypeEnum.Mix] = await proposalResponseMix.json()
    proposal[CustomerPlanTypeEnum.OneTime] = await proposalResponseOneTime.json()
    proposal[CustomerPlanTypeEnum.Subscription] = await proposalResponseSubscription.json()
  } catch (error) {
    console.debug({ error })
  }

  return proposal
}
