import { GlobalState } from 'little-state-machine'

export const setProposalFetchedAction = (globalState: GlobalState, payload: { value: boolean }) => {
  const { value } = payload

  if (value === undefined || value === null) {
    return {
      ...globalState
    }
  }

  return {
    ...globalState,
    isProposalFetched: value
  }
}
