import { translate } from 'src/client/common/translatable/translatable'

export enum SterilizationValuesEnum {
  STERILIZED = 'sterilized',
  NOT_STERILIZED = 'not-sterilized'
}
export const sterilizationButtons = [
  {
    label: translate('tak'),
    value: SterilizationValuesEnum.STERILIZED
  },
  {
    label: translate('nie'),
    value: SterilizationValuesEnum.NOT_STERILIZED
  }
]
