import { GlobalState } from 'little-state-machine'

export const removeProposalAction = (globalState: GlobalState) => {
  const newGlobalState = { ...globalState }

  delete newGlobalState.proposal

  return {
    ...newGlobalState
  }
}
