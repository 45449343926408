import Cookies from 'js-cookie'
import { PlanStorageState } from 'src/client/common/@types/plan-storage-state.types'

import { CookieStorage } from '../@enum/cookie-storage.enum'

export const getPlanData = async (): Promise<PlanStorageState | {}> => {
  const planData = Cookies.get(CookieStorage.PLAN_STORAGE_KEY)

  if (!planData) return {}

  try {
    const parsedPlanData = (await JSON.parse(planData)) as PlanStorageState

    return parsedPlanData
  } catch (error) {
    console.debug(
      'Wrong data format for plan-storage. Resetting to an empty object. Error log:',
      error
    )

    return {}
  }
}
