import { translate } from 'src/client/common/translatable/translatable'

export enum TreatsValuesEnum {
  GetsTreats = 'has-treats',
  DoesntGetTreats = 'no-treats'
}

export const treatsButtons = [
  {
    label: translate('dostaje'),
    value: TreatsValuesEnum.GetsTreats
  },
  {
    label: translate('nie dostaje'),
    value: TreatsValuesEnum.DoesntGetTreats
  }
]
