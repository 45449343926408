import { GlobalState } from 'little-state-machine'

export const setProposalFetchingAction = (
  globalState: GlobalState,
  payload: { value: boolean }
) => {
  const { value } = payload

  if (value === undefined || value === null) {
    return {
      ...globalState
    }
  }

  return {
    ...globalState,
    isProposalFetching: value
  }
}
